var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "min-h-screen flex-1 mt-1 bg-gray-100 px-4 pt-12 items-center"
  }, [_c('div', {
    staticClass: "bg-white w-fullrounded-lg shadow"
  }, [_c('div', {
    staticClass: "h-20 py-3 flex items-center border-gray-200 p-6 mt-5"
  }, [_c('div', {
    staticClass: "flex"
  }, [_c('button', {
    staticClass: "rounded-full flex justify-center items-center w-8 h-8 mr-3",
    class: _vm.$colors.primary,
    on: {
      "click": function click($event) {
        return _vm.RouterBack();
      }
    }
  }, [_c('i', {
    staticClass: "material-icons"
  }, [_vm._v("chevron_left")])]), _c('div', {
    staticClass: "text-xl font-bold text-gray-700"
  }, [_vm._v("Detail product")])])]), _c('div', {
    staticClass: "p-32"
  })])]);

}
var staticRenderFns = []

export { render, staticRenderFns }